// Warframe.com automated analytics

const analyticsElements = [];
const analyticsTriggers = [];
const logAnalytics = false;

let analyticsDebugWindow = null;

function elementOrParentAlreadyHasListener(element) {
    if (!element || analyticsElements.includes(element)) {
        return true;
    }
    let parent = element.parentElement;
    while (parent) {
        if (analyticsElements.includes(parent)) {
            return true;
        }
        parent = parent.parentElement;
    }

    return false;
}

function elementParentAnchor(element) {
    if (!element) return null;
    if (element.tagName.toLowerCase() === 'a') return element;

    let parent = element.parentElement;
    while (parent) {
        if (parent.tagName && parent.tagName.toLowerCase() === 'a') return parent;
        parent = parent.parentElement;
    }
    return null;
}

function detectAnalyticsPlacement(element) {
    if (!element) return null;

    let lastId = null;
    let e = element;
    while (e) {
        // Reached main page container, return previous id
        if (e.id === "mainContent") return lastId || e.id;

        // Save id and keep looking
        if (e.id) lastId = e.id;

        // Reached a section or masthead, which both require an id
        if (e.classList.contains('Section') || e.classList.contains('Masthead')) return lastId;
        e = e.parentElement;
    }
    return lastId;
}

function addDataLayerListener({element, event, payload, transform, source="direct"}) {
    element.addEventListener(event, (e) => {
        const p = {
            ...payload,
            authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0,
        };
        transform && transform(element, p);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(p);
    });
    analyticsElements.push(element);
    analyticsTriggers.push({
        element,
        payload,
        event,
        source,
    });

    if (logAnalytics) console.log(`adding analytics listener (${source})`, {element, payload});
}
function addDataLayerTrigger({selector, event, payload, transform, source}) {
    const elements = document.querySelectorAll(selector);
    if (elements && elements.length > 0) {
        for (let i = 0; i < elements.length; i ++) {
            addDataLayerListener({
                element: elements[i],
                event,
                payload,
                transform,
                source
            });
        }
    }
}

// Automated analytics
function autowireAnalytics() {
    // Subnavigation
    const AutowiredSubNav = document.querySelectorAll('.SubNavigationItem-link,.SubNavItem');
    AutowiredSubNav.forEach((element) => {
        if (element.hasAttribute('data-analytics-event')) return;
        if (elementOrParentAlreadyHasListener(element)) return;

        const payload = {
            event_name: 'link_click',
            creative_type: 'link',
            creative_title: element.getAttribute('data-label') || element.innerText.trim() || '(unknown)',
            creative_placement: 'subnav',
        };
        if (element.href && element.href !== "#") {
            payload.destination_url = element.href;
        }
        addDataLayerListener({
            element,
            event: 'click',
            payload,
            source: 'autowired - subnav'
        });
    });

    // Buttons
    const AutowiredButtons = document.querySelectorAll('.Button');
    AutowiredButtons.forEach((element) => {
        if (element.hasAttribute('data-analytics-event')) return;
        if (elementOrParentAlreadyHasListener(element)) return;

        const payload = {
            event_name: 'button_click',
            creative_type: 'button',
            creative_title: element.innerText.trim() || '(unknown)',
            creative_placement: detectAnalyticsPlacement(element) || 'unknown',
        };

        const parentAnchor = elementParentAnchor(element);
        if (parentAnchor) {
            if (parentAnchor.href && parentAnchor.href !== "#") {
                payload.destination_url = parentAnchor.href;
            }
            addDataLayerListener({
                element: parentAnchor,
                event: 'click',
                payload,
                source: 'autowired - button parent anchor'
            });
        } else {
            addDataLayerListener({
                element,
                event: 'click',
                payload,
                source: 'autowired - button'
            });
        }
    });

    // Card media anchors
    const AutowiredCardMedia = document.querySelectorAll('a.Card-media');
    AutowiredCardMedia.forEach((element) => {
        if (element.hasAttribute('data-analytics-event')) return;
        if (elementOrParentAlreadyHasListener(element)) return;

        let title = '(unknown)';
        if (element.parentElement) {
            const button = element.parentElement.querySelector('.Button');
            if (button) title = button.innerText.trim() || '(unknown)';
        }

        const payload = {
            event_name: 'card_click',
            creative_type: 'card',
            creative_title: title,
            creative_placement: detectAnalyticsPlacement(element) || 'unknown',
        };

        if (element.href && element.href !== "#") {
            payload.destination_url = element.href;
        }
        addDataLayerListener({
            element,
            event: 'click',
            payload,
            source: 'autowired - card media'
        });
    });

    // Tab interfaces
    const AutowiredTabs = document.querySelectorAll('.TabContainer');
    AutowiredTabs.forEach((element) => {
        const placement = detectAnalyticsPlacement(element) || 'unknown';
        const Tabs = element.querySelectorAll('.Tab');

        Tabs.forEach((tab) => {
            const title = tab.hasAttribute('data-label') ? tab.getAttribute('data-label') : tab.innerText.trim();
            const payload = {
                event_name: 'tab_click',
                creative_type: 'tab',
                creative_title: title || '(unknown)',
                creative_placement: placement,
            };
            addDataLayerListener({
                element: tab,
                event: 'click',
                payload,
                source: 'autowired - tab'
            });
        });
    });

    // Carousel components
    const AutowiredCarousel = document.querySelectorAll('.Carousel');
    AutowiredCarousel.forEach((element) => {
        const placement = detectAnalyticsPlacement(element) || 'unknown';
        const Prev = element.querySelector('.Carousel-prev');
        const Next = element.querySelector('.Carousel-next');

        if (Prev) {
            const payload = {
                event_name: 'carousel_click',
                creative_type: 'carousel',
                creative_title: 'prev',
                creative_placement: placement,
            };
            addDataLayerListener({
                element: Prev,
                event: 'click',
                payload,
                source: 'autowired - carousel'
            });
        }
        if (Next) {
            const payload = {
                event_name: 'carousel_click',
                creative_type: 'carousel',
                creative_title: 'next',
                creative_placement: placement,
            };
            addDataLayerListener({
                element: Next,
                event: 'click',
                payload,
                source: 'autowired - carousel'
            });
        }
    });

    // Remaining anchors
    const AutowiredAnchors = document.querySelectorAll('#mainContent a');
    AutowiredAnchors.forEach((element) => {
        if (element.hasAttribute('data-analytics-event')) return;
        if (elementOrParentAlreadyHasListener(element)) return;

        const payload = {
            event_name: 'link_click',
            creative_type: 'link',
            creative_title: element.innerText.trim() || '(unknown)',
            creative_placement: detectAnalyticsPlacement(element) || 'unknown',
        };

        if (element.href && element.href !== "#") {
            payload.destination_url = element.href;
        }
        addDataLayerListener({
            element,
            event: 'click',
            payload,
            source: 'autowired - link'
        });
    });
}

function syncAnalyticsHover(element) {
    analyticsElements.forEach((e) => {
        e.classList.toggle('analytics-highlight', e === element);
    });
}
function openAnalyticsDebug() {
    if (!analyticsDebugWindow) {
        analyticsDebugWindow = document.createElement('div');
        analyticsDebugWindow.classList.add('AnalyticsDebug');

        const title = document.createElement('div');
        title.classList.add('AnalyticsDebug-title');
        title.innerText = "Analytics Listeners";
        analyticsDebugWindow.appendChild(title);

        const table = document.createElement('div');
        table.classList.add('AnalyticsDebug-table');
        analyticsDebugWindow.appendChild(table);

        analyticsTriggers.forEach((trigger, index) => {
            let {element, payload, event, source} = trigger;
            const row = document.createElement('div');
            row.classList.add('AnalyticsDebug-row');
            if (source === 'direct') row.classList.add('is-direct');
            row.classList.add('is-collapsed');
            table.appendChild(row);

            // Inner block
            const inner = document.createElement('div');
            inner.classList.add('AnalyticsDebug-inner');
            row.appendChild(inner);

            // Info block
            const info = document.createElement('div');
            info.classList.add('AnalyticsDebug-info');
            inner.appendChild(info);

            // Index
            let column = document.createElement('div');
            column.classList.add('AnalyticsDebug-index');
            column.innerHTML = '' + (index + 1);
            info.appendChild(column);

            // Element
            let name;
            if (payload.creative_title) {
                name = payload.creative_placement + ': ' + payload.creative_title;
            } else {
                name = element.tagName.toLowerCase();
                if (element.id) {
                    name += "#" + element.id;
                }
            }
            column = document.createElement('div');
            column.classList.add('AnalyticsDebug-element');
            column.innerText = name;
            info.appendChild(column);

            // Event
            column = document.createElement('div');
            column.classList.add('AnalyticsDebug-event');
            column.innerText = event;
            info.appendChild(column);

            // Source
            column = document.createElement('div');
            column.classList.add('AnalyticsDebug-source');
            column.innerText = 'Source: ' + source;
            info.appendChild(column);

            // Payload
            const tokens = [];
            for (const key in payload) {
                if (payload.hasOwnProperty(key)) {
                    tokens.push(key + '=' + payload[key]);
                }
            }
            column = document.createElement('div');
            column.classList.add('AnalyticsDebug-payload');
            column.innerHTML = tokens.join('<br />');
            inner.appendChild(column);

            // Toggle
            column = document.createElement('div');
            column.classList.add('AnalyticsDebug-toggle');
            column.addEventListener('click', (e) => {
                e.stopPropagation();
                row.classList.toggle('is-collapsed');
            });
            row.appendChild(column);

            function sync() {
                syncAnalyticsHover(element);
            }
            function click() {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }

            row.addEventListener('mouseover', sync);
            row.addEventListener('click', click);
        });

        table.addEventListener('mouseout', () => {
            syncAnalyticsHover(null);
        });

        document.querySelector('body').appendChild(analyticsDebugWindow);
    }
    analyticsDebugWindow.classList.toggle('is-active');
}

module.exports = {
    elementOrParentAlreadyHasListener,
    elementParentAnchor,
    detectAnalyticsPlacement,
    addDataLayerListener,
    addDataLayerTrigger,
    autowireAnalytics,
    openAnalyticsDebug,
};
